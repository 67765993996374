<template>
    <div class="app-select-wrapper">
        <b-modal
            id="modal-primary"
            ok-only
            ok-title="Add"
            modal-class="modal-primary"
            centered
            title="Add a new app"
            @ok="createApp"
            >
            <b-card-text>
                <b-form-input v-model="newAppName" placeholder="What is the name of your app?" class="mb-2"></b-form-input>
                <p v-if="!appNameIsValid()" class="text-danger">The name contains illegal characters.</p>
                <p>The name cannot be modified afterwards!</p>
            </b-card-text>
        </b-modal>

        <strong class="mr-1">Adalo App:</strong>
        <b-dropdown :text="value || 'Default'" right variant="outline-primary" class="app-select-dropdown">
            <b-dropdown-item v-if="value" @click="$emit('input', null)"><em>Default</em></b-dropdown-item>
            <b-dropdown-item v-for="app in apps" :key="app" @click="$emit('input', app)">{{ app }}</b-dropdown-item>
            <b-dropdown-divider v-if="!viewOnly && apps.length" />
            <b-dropdown-item v-if="!viewOnly" v-b-modal.modal-primary>
                <feather-icon icon="PlusIcon" />
                Add app
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { BDropdown, BDropdownItem, BDropdownDivider, BModal, BCardText, BFormInput } from "bootstrap-vue";
import { setDoc, doc, getDocs, collection } from "firebase/firestore";
import { mapGetters } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
    components: {
        BDropdown,
        BDropdownItem,
        BDropdownDivider,
        BModal,
        BCardText,
        BFormInput
    },
    props: {
        value: String,
        viewOnly: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        apps: [],
        newAppName: ''
    }),
	computed: {
		...mapGetters({
			user: "auth/user"
		})
	},
    watch: {
        "user.data.uid"() {
            this.getApps();
        }
    },
    mounted() {
        if (this.user.data) this.getApps();
    },
    methods: {
        async getApps() {
            const apps = await getDocs(collection(this.$firestore, `users/${this.user.data.uid}/apps`))
            
            this.apps = [];
            apps.forEach(app => {
                if (this.$route.params.app != app.id) this.apps.push(app.id)
            })
        },
        appNameIsValid() {
            this.newAppName = this.newAppName.trim();
            return !(new RegExp(/__.*__/)).test(this.newAppName) && !this.newAppName.includes('/') && this.newAppName.toLowerCase() !== 'default';
        },
        async createApp(modal) {
            try {
                if (!this.appNameIsValid()) {
                    throw "App name is invalid."
                };
                if (this.apps.includes(this.newAppName)) {
                    throw "App with this name is already created."
                }

                await setDoc(doc(
                    this.$firestore, 
                    `users/${this.user.data.uid}/apps`, 
                    this.newAppName
                ), {});

                this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'App created.',
                            icon: 'EditIcon',
                            variant: 'success',
                        },
                    });

                this.$router.push('/settings/' + this.newAppName)
            } catch (err) {
                modal.preventDefault();
                console.error(err);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: err,
                        icon: 'EditIcon',
                        variant: 'danger',
                    },
                });
            }
        }
    }
}
</script>

<style scoped>
.app-select-wrapper {
    text-align: right;
}

.app-select-dropdown {
    display: inline-block;
}
</style>