
<template>
    <div>
        <AppSelect v-if="userPlan === 'Master Wizard'" 
            v-model="app"
            class="mb-2" view-only />
        <b-overlay
            variant="white"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
            :show="notifications === null"
        >
            <b-card title="Overview">
                <div v-if="notifications && notifications.length > 0" style="overflow-x:scroll">
                    <b-alert variant="warning" v-if="userPlan == 'Novice'">
                        <div class="alert-body">
                            Scheduled notifications will be shown here but <strong>will not be delivered</strong> until you upgrade to a paid plan.
                        </div>
                    </b-alert>
                    <b-alert v-if="userPlan === 'Novice'" variant="danger">
                        Scheduled notifications will be shown here but are only sent on paid plans!
                    </b-alert>
                    <b-alert variant="info" show>
                        <div class="alert-body">
                            Delivery time is not precise and may be delayed up to 5 minutes.
                        </div>
                    </b-alert>
                    <b-table :items="notifications" :fields="fields">
                        <template #cell(medium)="data">
                            <span  style="white-space: nowrap">
                                <feather-icon :icon="getMediumIcon(data.item.medium)" />
                                {{ getMediumTitle(data.item.medium) }}
                            </span>
                        </template>
                        <template #cell(message)="data">
                            <span v-if="data.item.medium == 'email'">
                                {{ data.item.subject }} <em>(Template: <a :href="`/email-templates/${data.item.templateID}`">{{ data.item.templateTitle }}</a>)</em>
                            </span>
                            <span v-if="data.item.medium == 'sms'">{{ data.item.message }}</span>
                            <span v-if="data.item.medium == 'push'">{{ data.item.title }} | {{ data.item.message }}</span>
                        </template>
                        <template #cell(to)="data">
                            {{ data.item.to }}
                        </template>
                        <template #cell(date)="data">
                            <span class="oneline">
                                {{ new Date(data.item.date).toLocaleDateString('en-US') }},
                                {{ new Date(data.item.date).toLocaleString('en-US', { timeStyle: 'short' }) }}
                                <span v-if="data.item.interval" :title="data.item.interval ? intervalText(data.item.interval) : null">
                                    <feather-icon icon="RefreshCwIcon" />
                                    <span v-if="data.item.times" class="times">{{ data.item.times }}</span>
                                </span>
                            </span>
                        </template>
                        <template #cell(actions)="data">
                            <span class="oneline">
                                <b-button variant="primary" class="mr-2" @click="copyId(data.item)">Copy ID</b-button>
                                <b-button variant="danger" @click="openConfirm(data.item)">Cancel</b-button>
                            </span>
                        </template>
                    </b-table>

                    <b-button v-if="hasMore" variant="link" @click="fetchNotifications()">Show More</b-button>
                </div>
                <p v-else>
                    You currently have no scheduled notifications ahead of you. Don't know what this is about? Check out our 
                    <a href="https://docs.abracadalo.com/notification-api" target="_blank">Notification API</a>.
                </p>
            </b-card>
        </b-overlay>
    </div>
</template>

<script>
import { BCard, BButton, BTable, BOverlay, BAlert } from "bootstrap-vue";
import { mapGetters } from "vuex";
import { collection, query, where, getDocs, getDoc, deleteDoc, doc, limit, orderBy, startAfter } from '@firebase/firestore';
import FeatherIcon from '../@core/components/feather-icon/FeatherIcon.vue';
import AppSelect from "./components/AppSelect.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import getUserPlan from "../stripe/getUserPlan";

export default {
    components: {
        BCard,
        BButton,
        BTable,
        BOverlay,
        BAlert,
        FeatherIcon,
        AppSelect
    },
    data: () => ({
        app: null,
        notifications: null,
        userPlan: 'Novice',
        fields: [
            { key: 'medium', label: 'Medium' },
            { key: 'message', label: 'Message' },
            { key: 'to', label: 'To' },
            { key: 'date', label: 'Next Delivery' },
            { key: 'actions', label: 'Actions' },
        ],
        perPage: 10,
        lastDoc: null,
        hasMore: false
    }),
	computed: {
		...mapGetters({
			user: "auth/user"
		})
	},
    watch: {
        "user.data.uid"() {
            this.lastDoc = null;
            this.fetchNotifications();
        },
        app() {
            if (this.app) this.$router.push('/scheduled-notifications/' + this.app);
            else this.$router.push('/scheduled-notifications');
            this.$nextTick(() => {
                this.lastDoc = null;
                this.fetchNotifications();
            });
        }
    },
    mounted() {
        this.app = this.$route.params.app || null;
        getUserPlan().then(res => {
            this.userPlan = res;
        })
        if (this.user.data) this.fetchNotifications();
    },
    methods: {
        getMediumTitle(medium) {
            switch (medium) {
                case "email":
                    return "E-Mail";
                case "sms":
                    return "SMS";
                case "push":
                    return "Push Notification";
            }
        },
        getMediumIcon(medium) {
            switch (medium) {
                case "email":
                    return "MailIcon";
                case "sms":
                    return "SmartphoneIcon";
                case "push":
                    return "BellIcon";
            }
        },
        async fetchNotifications() {
            if (!this.$http) {
                await new Promise(resolve => setTimeout(resolve, 1000));
            }

            this.$http.post('/dashboard/notifications', { app: this.app || 'default' }).then(async res => {
                this.notifications = []
                const tplCache = new Map();
                res.data.notifications.forEach(async (n) => {
                    let templateTitle;
                    if (n.medium == 'email') {
                        let tpl;
                        if (tplCache.has(n.templateID)) {
                            tpl = tplCache.get(n.templateID)
                        } else {
                            tpl = await this.fetchTemplate(n.templateID);
                            tplCache.set(n.templateID, tpl)
                        }
                        templateTitle = tpl.title;
                    }
                    this.notifications.push({
                        id: n._id,
                        date: new Date(n.date),
                        templateTitle,
                        ...n
                    })
                    console.log(this.notifications)
                });
            }).catch(err => {
                console.error(err)
            })
        },
        async fetchTemplate(id) {
            const tpl = await getDoc(doc(this.$firestore, "users", this.user.data.uid, "email_templates", id));
            return tpl.data()
        },
        copyId(item) {
            navigator.clipboard.writeText(item.id);
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: 'ID copied to your clipboard!',
                    icon: 'CopyIcon',
                    variant: 'success',
                },
            });
        },
		openConfirm(item) {
			this.$swal({
				title: 'Are you sure?',
				text: "You are about to cancel the next and all subsequent notifications coming from this setup.",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: 'Yes, delete it!',
				customClass: {
					confirmButton: 'btn btn-primary',
					cancelButton: 'btn btn-outline-danger ml-1',
				},
				buttonsStyling: false,
			}).then(result => {
				if (!result.value) return;

                this.$http.delete('/dashboard/notifications', { data: {id: item.id} }).then(() => {
                    this.notifications = this.notifications.filter(n => n.id !== item.id)
					this.$swal({
						icon: 'success',
						title: 'Deleted!',
						text: 'The notification has been canceled.',
						customClass: {
							confirmButton: 'btn btn-success',
						},
					});
                }).catch(e => {
                    console.error(e);

                    this.$swal({
                        icon: 'danger',
                        title: 'Oops, something went wrong!',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                })
			})
		},
        intervalText(interval) {
            const hours = interval / 3600000;
            if (hours % 24 !== 0) {
                if (hours === 1) return 'Hourly';
                return `Every ${hours} hours`;
            }

            const days = hours / 24;
            if (days % 7 !== 0 && days % 30 !== 0) {
                if (days === 1) return 'Daily';
                return `Every ${days} days`;
            }

            const weeks = days / 7;
            if (weeks % 7 !== 0) {
                if (weeks === 1) return 'Weekly';
                return `Every ${weeks} weeks`;
            }

            const months = days / 30;
            if (months % 30 !== 0) {
                if (months === 1) return 'Monthly';
                return `Every ${months} months`;
            }

            const years = days / 365;
            if (years === 1) return 'Yearly';
            return `Every ${years} years`;
        }
    }
}
</script>

<style lang="scss" scoped>
.times {
    padding-left: 4px;
    font-weight: bold;
}
.oneline {
    white-space: nowrap;
}
</style>